<template>
    <div>
      <div 
        class="walla-widget-root" 
        :data-walla-id="widgetId"
        :data-walla-page="widgetPage"
        :data-walla-leadformid="leadFormId"
      ></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WallaLaunchLead',
    props: {
      widgetId: {
        type: String,
        required: true,
      },
      widgetPage: {
        type: String,
        required: true,
      },
      leadFormId: {
        type: String,
        required: true,
      }
    },
    mounted() {
      // Log when the component is mounted
      console.log('WallaWidget component mounted.');
      this.reloadWidgetScript();
    },
    beforeDestroy() {
      // Clean up when the component is destroyed (e.g. page is navigated away from)
      console.log('WallaWidget component destroyed.');
      this.removeWidgetScript();
    },
    methods: {
      reloadWidgetScript() {
        // Remove the existing widget script if it exists
        this.removeWidgetScript();
  
        // Log that we are loading the widget script
        console.log('Loading Walla widget script...');
        
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://widget.hellowalla.com/loader/v1/walla-widget-loader.js';
        script.id = 'walla-widget-script';
        
        // Append the script to the document head
        document.head.appendChild(script);
      },
      
      removeWidgetScript() {
        const existingScript = document.getElementById('walla-widget-script');
        if (existingScript) {
          console.log('Removing existing Walla widget script...');
          existingScript.remove();
        }
      }
    }
  }
  </script>
  
  <style scoped>
  /* Add custom styling here if needed */
  </style>
  